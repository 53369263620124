import { useEffect, useState } from 'react';
import { IHomeProps, Workflow } from '../workflow/Workflow';
import { Link, Route, Routes } from 'react-router-dom';
import { Button, Empty, Space, Spin, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useAuth0 } from '@auth0/auth0-react';
import {
  CopyOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { baseUrl } from '../prompt/Prompt';
import axios from 'axios';

interface DataType {
  key: string;
  name: string;
  version: number;
  created_at: string;
  updated_at: string;
  id: string;
  active_workflow: string;
}

export const Project = (props: IHomeProps) => {
  const { setSliderElement } = props;
  const [projectList, setProjectList] = useState<DataType[]>([]);

  const [isCreatingProject, setIsCreatingProject] = useState(false);
  const [isProjectListLoading, setIsProjectListLoading] = useState(true);
  const [isProjectDuplicating, setIsProjectDuplicating] = useState(false);

  const { user } = useAuth0();

  const columns: ColumnsType<DataType> = [
    {
      title: 'Project Name',
      dataIndex: 'name',
      key: 'name',
      render: (name, record) => (
        <Space size="middle">
          <Link to={`${record.id}/workflow/${record.active_workflow}`}>
            {name}
          </Link>
        </Space>
      ),
    },
    {
      title: 'Latest Version',
      dataIndex: 'version',
      key: 'version',
    },
    {
      title: 'Chat UI Link',
      dataIndex: 'ui_link',
      key: 'ui_link',
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
    },
    {
      title: 'Updated At',
      dataIndex: 'updated_at',
      key: 'updated_at',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="primary"
            shape="circle"
            loading={isProjectDuplicating}
            onClick={() => duplicateProject(record.id)}
            icon={<CopyOutlined />}
          ></Button>
          <Button
            type="primary"
            shape="circle"
            icon={<DeleteOutlined />}
          ></Button>
        </Space>
      ),
    },
  ];

  const duplicateProject = (id: string) => {
    setIsProjectDuplicating(true);
    axios
      .post(`${baseUrl}/dev-ops-lab/v1/project/duplicate/${id}/`, {})
      .then(function (response) {
        setIsProjectDuplicating(false);
        setProjectList(response.data);
      })
      .catch(function (error) {
        setIsProjectDuplicating(false);
      });
  };

  useEffect(() => {
    setSliderElement(undefined);
    axios
      .post(`${baseUrl}/dev-ops-lab/v1/projects/`, {
        email: user!.email,
        name: user!.name,
      })
      .then(function (response) {
        setIsProjectListLoading(false);
        setProjectList(response.data);
      })
      .catch(function (error) {});
  }, []);

  const createProject = () => {
    setIsCreatingProject(true);
    axios
      .post(`${baseUrl}/dev-ops-lab/v1/projects/create/`, {
        email: user!.email,
        name: user!.name,
      })
      .then(function (response) {
        setIsCreatingProject(false);
        console.log(response);
        // setExamples(response.data);
      })
      .catch(function (error) {
        setIsCreatingProject(false);
      });
  };

  return (
    <div>
      {isProjectListLoading ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '100px',
          }}
        >
          <Spin />
        </div>
      ) : (
        <>
          <Button
            type="primary"
            style={{ marginBottom: 16 }}
            icon={<PlusOutlined />}
            loading={isCreatingProject}
            onClick={createProject}
          >
            New Project
          </Button>
          <Table
            columns={columns}
            dataSource={projectList}
            locale={{ emptyText: <Empty description={'No project'} /> }}
          />
        </>
      )}
    </div>
  );
};
