import React from 'react';
import logo from './logo.svg';
// import './App.css';
import { Auth0Provider } from '@auth0/auth0-react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Home } from './screens/Home';

function App() {
  const { user, isAuthenticated, loginWithRedirect, isLoading, error } =
    useAuth0();

  return (
    <>
      {isLoading ? (
        <>Loading...</>
      ) : isAuthenticated ? (
        <BrowserRouter>
          <Routes>
            <Route path="*" element={<Home />} />
          </Routes>
        </BrowserRouter>
      ) : (
        loginWithRedirect()
      )}

      {/* other routes */}
    </>
  );
}
export default App;
