import { Input } from 'antd';
import { BaseNode, INodeProps } from './BaseNode';
import React, { ReactNode, SyntheticEvent, memo } from 'react';
import { RightCircleOutlined } from '@ant-design/icons';
import { HandleType, Position, useReactFlow, useStoreApi } from 'reactflow';
import TextArea from 'antd/es/input/TextArea';

export default memo(
  ({
    id,
    data,
    isConnectable,
  }: {
    id: string;
    data: any;
    isConnectable: boolean;
  }) => {
    const { setNodes } = useReactFlow();
    const store = useStoreApi();

    const handleChange = (key: string, value: string) => {
      const { nodeInternals } = store.getState();
      setNodes(
        Array.from(nodeInternals.values()).map((node) => {
          if (node.id === id) {
            node.data = {
              ...node.data,
              [key]: value,
            };
          }

          return node;
        }),
      );
    };
    const inputNode: INodeProps = {
      id: id,
      label: 'Input',
      icon: <RightCircleOutlined />,
      nodeElements: [
        {
          key: 'input',
          element: (
            <>
              <TextArea
                className="nodrag nowheel"
                defaultValue={data?.input}
                onChange={(e) => handleChange('input', e.target.value)}
                autoSize={{ minRows: 3, maxRows: 8 }}
              />
            </>
          ),
          dragDropHandles: [{ type: 'source', position: Position.Right }],
        },
      ],
    };
    return (
      <>
        <BaseNode node={inputNode} isConnectable={isConnectable} />
      </>
    );
  },
);
