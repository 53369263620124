import {
  CopyOutlined,
  DatabaseOutlined,
  MessageOutlined,
  SlidersOutlined,
  SwapOutlined,
  ToolOutlined,
} from '@ant-design/icons';

import Sider from 'antd/es/layout/Sider';
import { Menu, MenuProps, theme } from 'antd';
import './WorkFlowSideBar.css';
import { NodeType } from './node/BaseNode';

export const WorkFlowSideBar = () => {
  const workflowSliderItems: any = [
    {
      key: '0',
      label: 'Input/Output',
      icon: <SwapOutlined />,
      children: [
        {
          key: '0_0',
          label: 'Input',
          draggable: true,
          onDragStart: (event: React.DragEvent) =>
            onDragStart(event, NodeType.InputNode),
        },
        {
          key: '0_1',
          label: 'Output',
          draggable: true,
          onDragStart: (event: React.DragEvent) =>
            onDragStart(event, NodeType.OutputNode),
        },
        {
          key: '0_2',
          label: 'Output-Input',
          draggable: true,
          onDragStart: (event: React.DragEvent) =>
            onDragStart(event, NodeType.OutputInputNode),
        },
      ],
    },
    {
      key: '1',
      label: 'LLMs',
      icon: <MessageOutlined />,
      children: [
        {
          key: '1_0',
          label: 'OpenAI',
          draggable: true,
          onDragStart: (event: React.DragEvent) =>
            onDragStart(event, NodeType.LLMOpenAI),
        },
      ],
    },
    {
      key: '2',
      label: 'Embedding',
      icon: <DatabaseOutlined />,
      children: [
        {
          key: '2_0',
          label: 'ChromaDB',
          draggable: true,
          onDragStart: (event: React.DragEvent) =>
            onDragStart(event, NodeType.EmbChroma),
        },
      ],
    },
    {
      key: '3',
      label: 'Data Loader',
      icon: <CopyOutlined />,
      children: [
        {
          key: '3_0',
          label: 'Document',
          draggable: true,
          onDragStart: (event: React.DragEvent) =>
            onDragStart(event, NodeType.DocumentLoader),
        },
      ],
    },
    {
      key: '4',
      label: 'Tools',
      icon: <ToolOutlined />,
      children: [
        {
          key: '4_1',
          label: 'Query Data',
          draggable: true,
          onDragStart: (event: React.DragEvent) =>
            onDragStart(event, NodeType.QueryDataNode),
        },
      ],
    },
    {
      key: '5',
      label: 'Utils',
      icon: <SlidersOutlined />,
      children: [
        {
          key: '5_1',
          label: 'Non Empty Data',
          draggable: true,
          onDragStart: (event: React.DragEvent) =>
            onDragStart(event, NodeType.NonEmptyData),
        },
        {
          key: '5_2',
          label: 'Json Key to Value',
          draggable: true,
          onDragStart: (event: React.DragEvent) =>
            onDragStart(event, NodeType.JsonKeyToValue),
        },
      ],
    },
  ];

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const onDragStart = (event: React.DragEvent, nodeType: any) => {
    event.dataTransfer.setData('application/reactflow', nodeType);
    event.dataTransfer.effectAllowed = 'move';
  };

  return (
    <Sider collapsible style={{ background: colorBgContainer }}>
      <Menu
        // theme="dark"
        selectedKeys={[]}
        mode="inline"
        items={workflowSliderItems}
        defaultOpenKeys={['0', '1', '2', '3']}
      />
    </Sider>
  );
};
