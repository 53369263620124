import { Input } from 'antd';
import { BaseNode, INodeProps } from './BaseNode';
import React, { ReactNode, SyntheticEvent, memo } from 'react';
import { RightCircleOutlined } from '@ant-design/icons';
import { HandleType, Position, useReactFlow, useStoreApi } from 'reactflow';
import TextArea from 'antd/es/input/TextArea';

export default memo(
  ({
    id,
    data,
    isConnectable,
  }: {
    id: string;
    data: any;
    isConnectable: boolean;
  }) => {
    const { setNodes } = useReactFlow();
    const store = useStoreApi();

    const handleChange = (key: string, value: string) => {
      const { nodeInternals } = store.getState();
      setNodes(
        Array.from(nodeInternals.values()).map((node) => {
          if (node.id === id) {
            node.data = {
              ...node.data,
              [key]: value,
            };
          }

          return node;
        }),
      );
    };
    const inputNode: INodeProps = {
      id: id,
      label: 'Query Data(PostgreSQL)',
      icon: <RightCircleOutlined />,
      headerColor: '#ffadd2',
      tagColor: '#c41d7f',
      nodeElements: [
        {
          key: 'host',
          label: 'Host',
          element: (
            <>
              <TextArea
                className="nodrag nowheel"
                defaultValue={data?.host}
                // value={data?.input}
                onChange={(e) => handleChange('host', e.target.value)}
                autoSize={{ minRows: 1, maxRows: 2 }}
              />
            </>
          ),
          dragDropHandles: [],
        },
        {
          key: 'port',
          label: 'Port',
          element: (
            <>
              <TextArea
                className="nodrag nowheel"
                defaultValue={data?.port}
                // value={data?.input}
                onChange={(e) => handleChange('port', e.target.value)}
                autoSize={{ minRows: 1, maxRows: 2 }}
              />
            </>
          ),
          dragDropHandles: [],
        },
        {
          key: 'user',
          label: 'User',
          element: (
            <>
              <TextArea
                className="nodrag nowheel"
                // value={data?.input}
                defaultValue={data?.user}
                onChange={(e) => handleChange('user', e.target.value)}
                autoSize={{ minRows: 1, maxRows: 2 }}
              />
            </>
          ),
          dragDropHandles: [],
        },
        {
          key: 'password',
          label: 'Password',
          element: (
            <>
              <Input.Password
                className="nodrag nowheel"
                defaultValue={data?.password}
                // value={data?.input}
                onChange={(e) => handleChange('password', e.target.value)}
              />
            </>
          ),
          dragDropHandles: [],
        },
        {
          key: 'database',
          label: 'Database',
          element: (
            <>
              <TextArea
                className="nodrag nowheel"
                defaultValue={data?.database}
                // value={data?.input}
                onChange={(e) => handleChange('database', e.target.value)}
                autoSize={{ minRows: 1, maxRows: 2 }}
              />
            </>
          ),
          dragDropHandles: [],
        },
        {
          key: 'query_key',
          label: 'Query key',
          element: (
            <>
              <TextArea
                className="nodrag nowheel"
                defaultValue={data?.query_key}
                // value={data?.input}
                onChange={(e) => handleChange('query_key', e.target.value)}
                autoSize={{ minRows: 1, maxRows: 2 }}
              />
            </>
          ),
          dragDropHandles: [],
        },
      ],
      dragDropHandles: [
        { type: 'target', position: Position.Left, label: 'Query' },
        {
          type: 'source',
          position: Position.Right,
          label: 'Data',
          id: 'data',
          top: '33',
        },
        {
          type: 'source',
          position: Position.Right,
          label: 'Error',
          id: 'error',
          top: '67',
        },
      ],
    };
    return (
      <>
        <BaseNode node={inputNode} isConnectable={isConnectable} />
      </>
    );
  },
);
