import { RightCircleOutlined } from '@ant-design/icons';
import { Input, Tag } from 'antd';
import React, { ReactNode, memo } from 'react';
import { Handle, HandleType, Position } from 'reactflow';
import InputNode from './InputNode';
import OutputNode from './OutputNode';
import OutputInputNode from './OutputInputNode';
import LLMOpenAI from './LLMOpenAI';
import EmbChroma from './EmbChroma';
import DocumentLoader from './DocumentLoader';
import './BaseNode.css';
import QueryDataNode from './QueryDataNode';
import NonEmptyData from './utils/NonEmptyData';
import JsonKeyToValue from './utils/JsonKeyToValue';

export interface IDragDropHandleProps {
  id?: string;
  type: HandleType;
  position: Position;
  top?: string;
  label?: string;
}
interface INodeElementProps {
  label?: string;
  element: ReactNode;
  key: string;
  dragDropHandles: Array<IDragDropHandleProps>;
}
export interface INodeProps {
  id: string;
  label: string;
  headerColor?: string;
  tagColor?: string;
  icon?: ReactNode;
  nodeElements: Array<INodeElementProps>;
  dragDropHandles?: Array<IDragDropHandleProps>;
}

interface IBaseNodeProps {
  node: INodeProps;
  isConnectable: boolean;
}

export enum NodeType {
  InputNode = 'inputNode',
  OutputNode = 'outputNode',
  OutputInputNode = 'outputInputNode',
  LLMOpenAI = 'llmOpenAI',
  EmbChroma = 'embChroma',
  DocumentLoader = 'documentLoader',
  QueryDataNode = 'queryDataNode',
  NonEmptyData = 'nonEmptyData',
  JsonKeyToValue = 'jsonKeyToValue',
}

export const nodeTypes: any = {
  [NodeType.InputNode]: InputNode,
  [NodeType.OutputNode]: OutputNode,
  [NodeType.OutputInputNode]: OutputInputNode,
  [NodeType.LLMOpenAI]: LLMOpenAI,
  [NodeType.EmbChroma]: EmbChroma,
  [NodeType.DocumentLoader]: DocumentLoader,
  [NodeType.QueryDataNode]: QueryDataNode,
  [NodeType.NonEmptyData]: NonEmptyData,
  [NodeType.JsonKeyToValue]: JsonKeyToValue,
};

export const NodeIdPrifix: { [k: string]: string } = {
  [NodeType.InputNode]: 'inp',
  [NodeType.OutputNode]: 'out',
  [NodeType.OutputInputNode]: 'o-i',
  [NodeType.LLMOpenAI]: 'llm',
  [NodeType.EmbChroma]: 'emb',
  [NodeType.DocumentLoader]: 'doc',
  [NodeType.QueryDataNode]: 'que',
  [NodeType.NonEmptyData]: 'ned',
  [NodeType.JsonKeyToValue]: 'ktv',
};

export const BaseNode = (props: IBaseNodeProps) => {
  const { node, isConnectable } = props;
  const getHandle = (dragDropHandle: IDragDropHandleProps) => {
    return (
      <Handle
        id={dragDropHandle.id}
        type={dragDropHandle.type}
        position={dragDropHandle.position}
        style={{
          background: '#555',
          top:
            dragDropHandle.position === Position.Left ||
            dragDropHandle.position === Position.Right
              ? dragDropHandle.top
                ? dragDropHandle.top + '%'
                : '50%'
              : '',
        }}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={isConnectable}
        children={
          <span
            style={{
              float:
                dragDropHandle.position === Position.Left ? 'right' : 'left',
              margin:
                dragDropHandle.position === Position.Left
                  ? '5px 10px'
                  : dragDropHandle.position === Position.Right
                  ? '5px 10px'
                  : dragDropHandle.position === Position.Top
                  ? '-20px 10px'
                  : '0px 10px',
            }}
          >
            {dragDropHandle.label}
          </span>
        }
      />
    );
  };
  return (
    <div
      style={{
        background: '#fff',
        border: '1px solid #777',
        borderRadius: '5px',
        display: 'flex',
        flexDirection: 'column',
        minWidth: '350px',
      }}
    >
      <div
        style={{
          background: node.headerColor ? node.headerColor : '#91caff',
          borderRadius: '4px 4px 0 0',
          padding: '10px',
        }}
      >
        {node.icon}
        <span style={{ padding: '0 5px' }}>
          <b>{node.label}</b>
        </span>
        <Tag color={node.tagColor ? node.tagColor : '#108ee9'}>{node.id}</Tag>
      </div>
      {node.nodeElements.map((row) => (
        <div
          style={{
            padding: '10px 10px',
            display: 'flex',
            flexDirection: 'column',
            gap: 6,
            position: 'relative',
          }}
        >
          <strong>{row.label}</strong>
          {row.element}
          {row.dragDropHandles.map((dragDropHandle) =>
            getHandle(dragDropHandle),
          )}
        </div>
      ))}
      {node.dragDropHandles?.map((dragDropHandle) => getHandle(dragDropHandle))}
    </div>
  );
};
