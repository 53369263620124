import {
  Button,
  Col,
  Collapse,
  CollapseProps,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Slider,
  Table,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ExampleTable } from './ExampleTable';
import { IHomeProps } from '../workflow/Workflow';

enum VariableType {
  object = 'object',
  array = 'array',
  string = 'string',
  integer = 'integer',
}

interface IFunction {
  name: string;
  description: string;
  parameters: IFunctionParameter;
}

interface IFunctionParameter {
  type: VariableType;
  description?: string;
  properties?: { [key: string]: IFunctionParameter };
  items?: IFunctionParameter;
  enum?: Array<string>;
  required?: Array<string>;
}

interface IPromptProps {
  id: string;
  name: string;
  prompt: string;
  model: string;
  temprature: number;
}

export interface IExampleProps {
  key: string;
  variableValueMap?: { [key: string]: string };
  result: string;
}

export const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const Prompt = (props: IHomeProps) => {
  const { promptConfigId } = useParams();
  const navigate = useNavigate();
  const [functions, setFunctions] = useState<Array<IFunction>>([
    {
      name: 'show_summary_graph',
      description: 'Show summary and graph(graph is optional)',
      parameters: {
        type: VariableType.object,
        properties: {
          summary: {
            type: VariableType.string,
            description:
              'Answer or summary of the question based on data provided',
          },
          graphs: {
            type: VariableType.array,
            description: 'List of relevant graphs. Can be null',
            items: {
              type: VariableType.object,
              properties: {
                graph_name: {
                  type: VariableType.string,
                  enum: ['MultiLine', 'Line', 'GroupedBar', 'Bar', 'Pie'],
                },
                config: {
                  type: VariableType.object,
                  properties: {
                    xField: { type: VariableType.string },
                    yField: { type: VariableType.string },
                    seriesField: { type: VariableType.string },
                    angleField: { type: VariableType.string },
                    colorField: { type: VariableType.string },
                  },
                },
              },
              required: ['graph_name', 'config'],
            },
          },
        },
        required: ['summary', 'graphs'],
      },
    },

    // {},
  ]);
  const [promptConfig, setPromptConfig] = useState<IPromptProps>({
    id: '',
    name: '',
    prompt: '',
    model: 'gpt-3.5-turbo-16k',
    temprature: 0.0,
  });
  const [examples, setExamples] = useState<Array<IExampleProps>>([]);
  const [variables, setVariables] = useState<Array<string>>([]);
  const [isSaveBtnLoading, setIsSaveBtnLoading] = useState(false);
  // const [isSaveBtnDisabled, setIsSaveBtnDisabled] = useState(true);
  const [isRunBtnLoading, setIsRunBtnLoading] = useState(false);
  const { setSliderElement } = props;
  useEffect(() => {
    setSliderElement(undefined);
  }, []);

  useEffect(() => {
    console.log(promptConfigId);
  }, []);

  const VariableTypeParameterMap = {
    [VariableType.object]: ['properties', 'required'],
    [VariableType.array]: ['description', 'items'],
    [VariableType.string]: ['description', 'enum'],
    [VariableType.integer]: ['description', 'enum'],
  };

  const UiForVariable = (parameter: IFunctionParameter): React.ReactNode[] => {
    return VariableTypeParameterMap[parameter.type].map((key) => {
      if (key === 'properties') {
        return (
          <>
            <Form.Item label="Variables">
              {getVariables(parameter.properties)}
            </Form.Item>
          </>
        );
      }
      if (key === 'items') {
        return (
          <>
            <Form.Item label="Item type">
              <Select
                defaultValue={parameter.items?.type}
                style={{ width: '100%' }}
                options={Object.keys(VariableType).map((key) => ({
                  value: VariableType[key as keyof typeof VariableType],
                  label: key,
                }))}
              />
            </Form.Item>
            <>{UiForVariable(parameter.items!)}</>
          </>
        );
      }
      if (key === 'description') {
        return (
          <>
            <Form.Item label="Description">
              <Input
                placeholder="Description"
                allowClear
                defaultValue={parameter.description}
                // onChange={onChange}
              />
            </Form.Item>
          </>
        );
      }
      if (key === 'enum') {
        return (
          <>
            <Form.Item label="Values">
              <Input
                placeholder="Possible Values"
                allowClear
                defaultValue={parameter.enum}
                // onChange={onChange}
              />
            </Form.Item>
          </>
        );
      }
    });
  };

  const getVariables = (properties?: { [key: string]: IFunctionParameter }) => {
    return (
      <>
        {properties &&
          Object.keys(properties || {}).map((variableKey, index) => (
            <>
              <Form.Item
                label="Name"
                // name={['parameters', 'properties', variableKey, 'item']}
              >
                <Input
                  placeholder="Variable name"
                  allowClear
                  defaultValue={variableKey}
                  // onChange={onChange}
                />
              </Form.Item>
              <Form.Item
                label="Type"
                name={[
                  'parameters',
                  'properties',
                  variableKey,
                  'items',
                  'type',
                ]}
              >
                <Select
                  defaultValue={properties[variableKey].type}
                  style={{ width: '100%' }}
                  options={Object.keys(VariableType).map((key) => ({
                    value: VariableType[key as keyof typeof VariableType],
                    label: key,
                  }))}
                />
              </Form.Item>
              {UiForVariable(properties[variableKey])}
              <>
                {index !== Object.keys(properties || {}).length - 1 && (
                  <Divider />
                )}
              </>
            </>
          ))}
      </>
    );
  };

  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: `Prompt`,
      children: (
        <>
          <Input
            placeholder="Name"
            allowClear
            value={promptConfig.name}
            onChange={(v) =>
              setPromptConfig({ ...promptConfig, name: v.target.value })
            }
            // onChange={onChange}
          />
          <br />
          <br />
          <TextArea
            placeholder="Prompt"
            allowClear
            autoSize={{ minRows: 4, maxRows: 6 }}
            value={promptConfig.prompt}
            onChange={(v) =>
              setPromptConfig({ ...promptConfig, prompt: v.target.value })
            }
            // onChange={onChange}
          />
        </>
      ),
    },
    {
      key: '2',
      label: 'Config',
      children: (
        <>
          <Form layout="horizontal" initialValues={promptConfig}>
            <Form.Item label="Model" name="model">
              <Select
                style={{ width: '100%' }}
                options={[
                  { value: 'gpt-3.5-turbo-16k', label: 'gpt-3.5-turbo-16k' },
                  { value: 'gpt-3.5-turbo', label: 'gpt-3.5-turbo' },
                ]}
                value={promptConfig.model}
                onChange={(v) => setPromptConfig({ ...promptConfig, model: v })}
              />
            </Form.Item>
            <Form.Item label="Temprature" name="temprature">
              <Row>
                <Col span={18}>
                  <Slider
                    min={0}
                    max={2}
                    value={promptConfig.temprature}
                    step={0.1}
                    onChange={(v) =>
                      setPromptConfig({ ...promptConfig, temprature: v })
                    }
                  />
                </Col>
                <Col span={4}>
                  <InputNumber
                    min={0}
                    max={2}
                    style={{ margin: '0 16px' }}
                    step={0.1}
                    value={promptConfig.temprature}
                    onChange={(v) =>
                      setPromptConfig({ ...promptConfig, temprature: v! })
                    }
                  />
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </>
      ),
    },
    // {
    //   key: '3',
    //   label: 'Function',
    //   children: (
    //     <>
    //       {functions.map((row) => (
    //         <>
    //           <Form
    //             layout="horizontal"
    //             initialValues={row}
    //             labelCol={{ span: 4 }}
    //             // onFinish={onFinish}
    //             disabled
    //           >
    //             <Form.Item label="Name" name="name">
    //               <Input
    //                 placeholder="Name"
    //                 allowClear
    //                 // onChange={onChange}
    //               />
    //             </Form.Item>
    //             <Form.Item label="Description" name="description">
    //               <Input
    //                 placeholder="Description"
    //                 allowClear
    //                 // onChange={onChange}
    //               />
    //             </Form.Item>
    //             <Form.Item
    //               label="Variables"
    //               // name="parameters"
    //             >
    //               {getVariables(row.parameters.properties)}
    //             </Form.Item>
    //           </Form>
    //         </>
    //       ))}
    //     </>
    //   ),
    // },
  ];

  const onChange = (key: string | string[]) => {
    console.log(key);
  };
  const getExampleResults = () => {
    // console.log(examples);
    axios
      .post(
        `${baseUrl}/dev-ops-lab/prompt_configs/${promptConfigId}/examples/`,
        {
          examples: examples,
        },
      )
      .then(function (response) {
        console.log(response);
        setExamples(response.data);
      });
  };

  const savePromptConfig = () => {
    axios
      .post(`${baseUrl}/dev-ops-lab/prompt/add-or-update`, promptConfig)
      .then(function (response) {
        setPromptConfig(response.data);
        setVariables(
          response.data.prompt
            .match(/{{(.*?)}}/g)
            ?.map((match: string) => match.replace(/[{}]/g, '')) ?? [],
        );
        navigate('/prompt/' + response.data.id);
      });
  };

  return (
    <>
      <div style={{ width: '600px', display: 'flex', flexDirection: 'column' }}>
        <div>
          <Collapse
            onChange={onChange}
            items={items}
            defaultActiveKey={['1']}
          />
          <div style={{ float: 'right', margin: '10px 0' }}>
            <Button
              onClick={savePromptConfig}
              type="primary"
              // disabled={isSaveBtnDisabled}
            >
              Save
            </Button>
          </div>
        </div>
        <Divider />
        {promptConfigId && (
          <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <h3 style={{ flex: 1 }}>Examples</h3>
              <Button onClick={getExampleResults} type="primary">
                Run all
              </Button>
            </div>
            <ExampleTable
              examples={examples}
              setExamples={setExamples}
              variables={variables}
            />
          </>
        )}
      </div>
    </>
  );
};
