import { Input } from 'antd';
import { BaseNode, INodeProps } from './BaseNode';
import React, { ReactNode, SyntheticEvent, memo } from 'react';
import { RightCircleOutlined, SwapOutlined } from '@ant-design/icons';
import { HandleType, Position, useReactFlow, useStoreApi } from 'reactflow';
import TextArea from 'antd/es/input/TextArea';

export default memo(
  ({
    id,
    data,
    isConnectable,
  }: {
    id: string;
    data: any;
    isConnectable: boolean;
  }) => {
    const inputNode: INodeProps = {
      id: id,
      label: 'Output-Input',
      headerColor: '#87e8de',
      tagColor: '#08979c',
      icon: <SwapOutlined />,
      nodeElements: [
        {
          key: 'input',
          element: (
            <>
              <TextArea
                className="nodrag nowheel"
                value={data.result}
                autoSize={{ minRows: 3, maxRows: 8 }}
                readOnly
              />
            </>
          ),
          dragDropHandles: [
            { type: 'target', position: Position.Left },
            { type: 'source', position: Position.Right },
          ],
        },
      ],
    };
    return (
      <>
        <BaseNode node={inputNode} isConnectable={isConnectable} />
      </>
    );
  },
);
