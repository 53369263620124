import React, { ReactNode, useState } from 'react';
import {
  ApartmentOutlined,
  DesktopOutlined,
  FileOutlined,
  HighlightOutlined,
  PieChartOutlined,
  SettingOutlined,
  SlidersOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Breadcrumb, Layout, Menu, theme } from 'antd';
import cruxLogo from '../assets/logo-black.png';
import { Link, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { Prompt } from './prompt/Prompt';
import { Workflow } from './workflow/Workflow';
import { Tools } from './Tools';
import { Function } from './Function';
import { JsxAttribute, JsxElement } from 'typescript';
import { Project } from './project/Project';

const { Header, Content, Footer, Sider } = Layout;
type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

const items: MenuItem[] = [
  getItem('Project', '', <ApartmentOutlined />),
  getItem('Prompt', 'prompt', <HighlightOutlined />),
  getItem('Function', 'function', <SlidersOutlined />),
  //   getItem('User', 'sub1', <UserOutlined />, [
  //     getItem('Tom', '3'),
  //     getItem('Bill', '4'),
  //     getItem('Alex', '5'),
  //   ]),
  //   getItem('Team', 'sub2', <TeamOutlined />, [
  //     getItem('Team 1', '6'),
  //     getItem('Team 2', '8'),
  //   ]),
  getItem('Fine-Tune', 'fine-tune', <SettingOutlined />),
];

export const Home = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [currentTab, setCurrentTab] = useState(
    window.location.pathname.split('/')[1],
  );
  const navigate = useNavigate();

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const onClick: MenuProps['onClick'] = (e) => {
    console.log('click ', e);
    setCurrentTab(e.key);
    navigate('/' + e.key);
  };

  const [sliderElement, setSliderElement] = useState<ReactNode>();
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header
        style={{
          background: colorBgContainer,
          padding: '0 20px',
          // justifyContent: 'right',
          position: 'sticky',
          top: 0,
          zIndex: 1,
          width: '100%',
          display: 'flex',
          alignItems: 'right',
        }}
      >
        <div style={{ marginTop: '10px' }}>
          <img
            src={cruxLogo}
            style={{ width: '100px', cursor: 'pointer' }}
            onClick={() => {
              window.open('https://getcrux.ai/', '_blank');
            }}
          />
        </div>
        <Menu
          // theme="light"
          style={{ flex: 1, justifyContent: 'right' }}
          mode="horizontal"
          selectedKeys={[currentTab]}
          onClick={onClick}
          items={items}
        />
      </Header>
      <Layout style={{ marginTop: '10px', flex: 1 }}>
        {sliderElement && sliderElement}
        <Layout>
          <Content style={{ margin: '0 16px', flex: 1, display: 'flex' }}>
            <div
              style={{
                padding: 24,
                minHeight: '100%',
                width: '-webkit-fill-available',
                background: colorBgContainer,
              }}
            >
              <Routes>
                <Route>
                  <Route
                    path=""
                    element={<Project setSliderElement={setSliderElement} />}
                  />
                  <Route
                    path=":projectId/workflow/:workflowId"
                    element={<Workflow setSliderElement={setSliderElement} />}
                  />
                </Route>
                <Route
                  path="/prompt/:promptConfigId?"
                  element={<Prompt setSliderElement={setSliderElement} />}
                />
                <Route
                  path="function"
                  element={<Function setSliderElement={setSliderElement} />}
                />
                <Route
                  path="fine-tune"
                  element={<Tools setSliderElement={setSliderElement} />}
                />
              </Routes>
            </div>
          </Content>
          <Footer style={{ textAlign: 'center' }}>Crux ©2023</Footer>
        </Layout>
      </Layout>
    </Layout>
  );
};
