import { useEffect } from 'react';
import { IHomeProps } from './workflow/Workflow';

export const Tools = (props: IHomeProps) => {
  const { setSliderElement } = props;
  useEffect(() => {
    setSliderElement(undefined);
  }, []);
  return <>fine-tune</>;
};
