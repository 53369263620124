import ReactFlow, {
  useNodesState,
  useEdgesState,
  addEdge,
  MiniMap,
  Controls,
  Background,
  ReactFlowProvider,
  OnInit,
  ReactFlowInstance,
  Node,
  XYPosition,
  Edge,
  MarkerType,
  ConnectionLineType,
  Handle,
  Position,
} from 'reactflow';
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import 'reactflow/dist/style.css';
import { MenuItemType } from 'antd/es/menu/hooks/useItems';
import {
  DesktopOutlined,
  PlayCircleOutlined,
  SaveOutlined,
  SwapOutlined,
  TabletOutlined,
} from '@ant-design/icons';
import { WorkFlowSideBar } from './WorkFlowSideBar';
import InputNode from './node/InputNode';
import OutputNode from './node/OutputNode';
import { NodeIdPrifix, NodeType, nodeTypes } from './node/BaseNode';
import { Button, FloatButton, Spin } from 'antd';
import axios from 'axios';
import { baseUrl } from '../prompt/Prompt';
import { useParams } from 'react-router-dom';
import { CustomInput } from '../../utils/CustomeInput';

export interface IHomeProps {
  setSliderElement: (element: ReactNode) => void;
}

export const Workflow = (props: IHomeProps) => {
  const { setSliderElement } = props;
  const { projectId, workflowId } = useParams();
  const reactFlowWrapper = useRef<HTMLDivElement | null>(null);
  const [reactFlowInstance, setReactFlowInstance] =
    useState<ReactFlowInstance<any, any>>();
  const [nodes, setNodes, onNodesChange] = useNodesState<Node[]>([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [isWorkflowRunning, setIsWorkflowRunning] = useState(false);
  const [isWorkflowLoading, setIsWorkflowLoading] = useState(true);
  const [isWorkflowUpdating, setIsWorkflowUpdating] = useState(false);
  const [isWorkflowNameUpdating, setIsWorkflowNameUpdating] = useState(false);
  const [workFlowName, setWorkFlowName] = useState('');

  const getId = (nodeType: NodeType, prifix: string) => {
    console.log(
      nodes,
      nodeType,
      nodes.filter((row) => row.type === nodeType),
    );
    var nodeTmpIdArray = nodes
      .filter((row) => row.type === nodeType)
      .map((row) => parseInt(row.id.split('-').pop()!));
    return `${prifix}-${
      nodeTmpIdArray.length > 0 ? Math.max(...nodeTmpIdArray) + 1 : 0
    }`;
  };

  useEffect(() => {
    setSliderElement(<WorkFlowSideBar />);
    axios
      .get(`${baseUrl}/dev-ops-lab/v1/workflow/${workflowId}/`)
      .then(function (response) {
        setIsWorkflowLoading(false);
        setWorkFlowName(response.data.project_name);
        setNodes(response.data.nodes);
        setEdges(response.data.edges);
        console.log(response.data.nodes);
        // setExamples(response.data);
      })
      .catch(function (error) {
        setIsWorkflowLoading(false);
      });
  }, []);

  const onDragOver = useCallback((event: React.DragEvent) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onDrop = useCallback(
    (event: React.DragEvent) => {
      event.preventDefault();

      const reactFlowBounds =
        reactFlowWrapper?.current?.getBoundingClientRect();
      const type: string = event.dataTransfer.getData('application/reactflow');

      // check if the dropped element is valid
      if (typeof type === 'undefined' || !type) {
        return;
      }

      const position: XYPosition = reactFlowInstance!.project({
        x: event.clientX - reactFlowBounds!.left,
        y: event.clientY - reactFlowBounds!.top,
      });
      const newNode: Node = {
        id: getId(type as NodeType, NodeIdPrifix[type]),
        type,
        position,
        data: {},
      };

      setNodes((nds) => nds.concat(newNode));
    },
    [reactFlowInstance, nodes],
  );

  const onConnect = useCallback(
    (params: any) => setEdges((eds) => addEdge(params, eds)),
    [setEdges],
  );

  const runWorkflow = () => {
    console.log(nodes);
    console.log(edges);
    const tmpNodes = [...nodes];
    setIsWorkflowRunning(true);
    axios
      .post(`${baseUrl}/dev-ops-lab/run/workflow`, {
        nodes: tmpNodes.map((row) => {
          return { ...row, data: { ...row.data, result: null } };
        }),
        edges: edges,
      })
      .then(function (response) {
        setIsWorkflowRunning(false);
        console.log(response);
        setNodes(response.data.nodes);
        // setExamples(response.data);
      })
      .catch(function (error) {
        setIsWorkflowRunning(false);
      });
  };
  const updateWorkflow = () => {
    setIsWorkflowUpdating(true);
    const tmpNodes = [...nodes];
    axios
      .put(`${baseUrl}/dev-ops-lab/v1/workflow/${workflowId}/`, {
        nodes: tmpNodes.map((row) => {
          return { ...row, data: { ...row.data, result: null } };
        }),
        edges: edges,
      })
      .then(function (response) {
        setIsWorkflowUpdating(false);
      })
      .catch(function (error) {
        // setIsWorkflowRunning(false);
      });
  };

  const updateWorkflowName = (value: string) => {
    setIsWorkflowNameUpdating(true);
    axios
      .put(`${baseUrl}/dev-ops-lab/v1/project/${projectId}/`, {
        name: value,
      })
      .then(function (response) {
        setIsWorkflowNameUpdating(false);
      })
      .catch(function (error) {
        setIsWorkflowNameUpdating(false);
      });
  };

  return (
    <>
      <ReactFlowProvider>
        {isWorkflowLoading ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '100px',
            }}
          >
            <Spin />
          </div>
        ) : (
          <div
            style={{ width: '100%', height: '100%', position: 'relative' }}
            ref={reactFlowWrapper}
          >
            <ReactFlow
              minZoom={0.1}
              nodes={nodes}
              edges={edges}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              onConnect={onConnect}
              onInit={setReactFlowInstance}
              onDrop={onDrop}
              onDragOver={onDragOver}
              nodeTypes={nodeTypes}
              attributionPosition="top-right"
              defaultEdgeOptions={{
                type: 'smoothstep',
                markerEnd: {
                  type: MarkerType.ArrowClosed,
                },
              }}
            >
              <Controls />
              <Background color="#aaa" gap={16} />
            </ReactFlow>
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
              }}
            >
              <CustomInput
                value={workFlowName}
                isUpdating={isWorkflowNameUpdating}
                updateValue={updateWorkflowName}
              />
            </div>
            <div
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                display: 'flex',
                gap: 5,
              }}
            >
              <Button
                type="primary"
                icon={<PlayCircleOutlined />}
                onClick={runWorkflow}
                loading={isWorkflowRunning}
              >
                Run
              </Button>
              <Button
                type="primary"
                icon={<SaveOutlined />}
                onClick={updateWorkflow}
                loading={isWorkflowUpdating}
              >
                Update
              </Button>
              <Button
                type="primary"
                icon={<DesktopOutlined />}
                // onClick={updateWorkflow}
                loading={isWorkflowUpdating}
              >
                Export UI
              </Button>
            </div>
          </div>
        )}
      </ReactFlowProvider>
    </>
  );
};
