import { Checkbox, Input, InputNumber, Select, Slider, Tag } from 'antd';
import { BaseNode, IDragDropHandleProps, INodeProps } from './BaseNode';
import React, {
  ReactNode,
  SyntheticEvent,
  memo,
  useEffect,
  useState,
} from 'react';
import { HandleType, Position, useReactFlow, useStoreApi } from 'reactflow';
import TextArea from 'antd/es/input/TextArea';
import Editor, { DiffEditor, useMonaco, loader } from '@monaco-editor/react';

export default memo(
  ({
    id,
    data,
    isConnectable,
  }: {
    id: string;
    data: any;
    isConnectable: boolean;
  }) => {
    const { setNodes } = useReactFlow();
    const store = useStoreApi();
    const [edges, setEdges] = useState(store.getState().edges);

    useEffect(() => {
      if (!data.temperature) {
        handleChange('temperature', '0');
      }
    }, []);

    useEffect(() => {
      const handleStoreChange = () => {
        setEdges(store.getState().edges.filter((edge) => edge.target === id));
      };
      const unsubscribe = store.subscribe(handleStoreChange);
      return () => {
        unsubscribe();
      };
    }, []);

    const formatInputValue = (inputValue: string) => {
      return inputValue.split(/({.*?})/g).map((text, index) => {
        if (text.startsWith('{') && text.endsWith('}')) {
          return (
            <span key={index} style={{ color: 'red' }}>
              {text}
            </span>
          );
        } else {
          return text;
        }
      });
    };
    const handleChange = (key: string, value: string) => {
      const { nodeInternals } = store.getState();
      setNodes(
        Array.from(nodeInternals.values()).map((node) => {
          if (node.id === id) {
            node.data = {
              ...node.data,
              [key]: value,
            };
          }

          return node;
        }),
      );
    };

    const functionsHandles = () => {
      try {
        JSON.parse(data.functions);
        // console.log('hi', data.functions);
        return true;
      } catch (error) {
        // console.log('hi', error);
        return false;
      }
    };

    const inputNode: INodeProps = {
      id: id,
      label: 'OpenAI',
      headerColor: '#ffd591',
      tagColor: '#d46b08',
      // icon: <RightCircleOutlined />,
      nodeElements: [
        {
          label: 'Model',
          key: 'model',
          element: (
            <>
              <Select
                className="nodrag nowheel"
                // style={{ zIndex: 999 }}
                defaultValue={data.model ? data.model : 'gpt-3.5-turbo'}
                // value={data[id]?.model}
                onChange={(value) => handleChange('model', value)}
                options={[
                  { value: 'gpt-4', label: 'gpt-4' },
                  { value: 'gpt-3.5-turbo', label: 'gpt-3.5-turbo' },
                  { value: 'gpt-3.5-turbo-16k', label: 'gpt-3.5-turbo-16k' },
                ]}
              />
            </>
          ),
          dragDropHandles: [],
        },
        {
          label: 'System',
          key: 'system',
          element: (
            <>
              <TextArea
                className="nodrag nowheel"
                // value={data[id]?.system}
                defaultValue={data.system}
                onChange={(e) => handleChange('system', e.target.value)}
                autoSize={{ minRows: 3, maxRows: 8 }}
              >
                Hi
              </TextArea>
            </>
          ),
          dragDropHandles: [],
        },
        {
          label: 'Prompt',
          key: 'prompt',
          element: (
            <>
              <TextArea
                className="nodrag nowheel"
                defaultValue={data?.prompt}
                onChange={(e) => handleChange('prompt', e.target.value)}
                autoSize={{ minRows: 3, maxRows: 8 }}
              />
            </>
          ),
          dragDropHandles: [],
        },
        {
          label: 'Functions',
          key: 'functions',
          element: (
            <>
              <TextArea
                className="nodrag nowheel"
                defaultValue={data?.functions}
                // value={data[id]?.prompt}
                onChange={(e) => handleChange('functions', e.target.value)}
                autoSize={{ minRows: 3, maxRows: 8 }}
              />
            </>
          ),
          dragDropHandles: functionsHandles()
            ? JSON.parse(data.functions).map(
                (row: { name: string }, index: number) => {
                  return {
                    type: 'source',
                    position: Position.Right,
                    label: row.name,
                    id: row.name,
                    top:
                      (100 * (index + 1)) /
                      (JSON.parse(data.functions).length + 1),
                  };
                },
              )
            : [],
        },
        {
          label: 'Function to use(Leave blank for auto)',
          key: 'function_to_use',
          element: (
            <>
              <TextArea
                className="nodrag nowheel"
                // value={data[id]?.prompt}
                defaultValue={data?.function_to_use}
                onChange={(e) =>
                  handleChange('function_to_use', e.target.value)
                }
                autoSize={{ minRows: 1, maxRows: 2 }}
              />
            </>
          ),
          dragDropHandles: [],
        },
        {
          key: 'temperature',
          label: 'Temperature',
          element: (
            <>
              <div style={{ display: 'flex' }} className="nodrag">
                <Slider
                  style={{ flex: 1 }}
                  min={0}
                  max={2}
                  value={data?.temperature}
                  step={0.1}
                  onChange={(v) =>
                    handleChange('temperature', v ? v.toString() : '0')
                  }
                />
                <InputNumber
                  min={0}
                  max={2}
                  style={{ margin: '0 0 0 16px' }}
                  step={0.1}
                  value={data?.temperature}
                  onChange={(v) =>
                    handleChange('temperature', v ? v.toString() : '0')
                  }
                />
              </div>
            </>
          ),
          dragDropHandles: [],
        },
        {
          label: 'Inputs',
          key: 'inputs',
          element: (
            <div>
              {edges.map((edge) => (
                <Tag>{edge.source}</Tag>
              ))}
            </div>
          ),
          dragDropHandles: [],
        },
        {
          key: 'is_conversational',
          element: (
            <div>
              <Checkbox
                checked={Boolean(data.is_conversational)}
                onChange={(v) => {
                  handleChange(
                    'is_conversational',
                    v.target.checked ? 'true' : '',
                  );
                  console.log(Boolean('False'));
                }}
              >
                Conversational
              </Checkbox>
            </div>
          ),
          dragDropHandles: [],
        },
        {
          key: 'conversational_prompt',
          element: (
            <>
              {data.is_conversational ? (
                <>
                  <strong>Conversational Prompt</strong>
                  <TextArea
                    className="nodrag nowheel"
                    defaultValue={data?.conversational_prompt}
                    // value={data[id]?.prompt}
                    onChange={(e) =>
                      handleChange('conversational_prompt', e.target.value)
                    }
                    autoSize={{ minRows: 3, maxRows: 8 }}
                  />
                </>
              ) : (
                <></>
              )}
            </>
          ),
          dragDropHandles: [],
        },
      ],
      dragDropHandles: [
        {
          type: 'target' as HandleType,
          position: Position.Left,
          label: 'input',
        },
        {
          id: 'completion',
          type: 'source' as HandleType,
          position: Position.Right,
          label: 'completion',
        },
      ],
    };
    return (
      <>
        <BaseNode node={inputNode} isConnectable={isConnectable} />
      </>
    );
  },
);
