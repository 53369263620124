import { Input, Upload, UploadFile, UploadProps } from 'antd';
import { BaseNode, INodeProps } from './BaseNode';
import React, { ReactNode, SyntheticEvent, memo, useState } from 'react';
import { InboxOutlined, RightCircleOutlined } from '@ant-design/icons';
import { HandleType, Position, useReactFlow, useStoreApi } from 'reactflow';
import TextArea from 'antd/es/input/TextArea';
import { RcFile } from 'antd/es/upload';

const { Dragger } = Upload;

export default memo(
  ({
    id,
    data,
    isConnectable,
  }: {
    id: string;
    data: any;
    isConnectable: boolean;
  }) => {
    const { setNodes } = useReactFlow();
    const store = useStoreApi();

    const props: UploadProps = {
      maxCount: 1,
      onRemove: (file) => {
        handleChange('file', undefined);
      },
      beforeUpload: (file) => {
        let reader = new FileReader();
        reader.onloadend = function (e) {
          handleChange('file', reader.result);
          console.log(reader.result);
        };
        reader.readAsDataURL(file);

        // const reader = new FileReader();
        // reader.onload = (event) => {
        //   const fileContent = event?.target?.result;
        //   handleChange('file', fileContent);
        // };
        // reader.readAsText(file);
        // const reader = new FileReader();
        // reader.onload = function () {
        //     const arrayBuffer = reader.result as ArrayBuffer; // Cast to ArrayBuffer
        //     const uint8Array: any = new Uint8Array(arrayBuffer);
        //     const base64String = btoa(String.fromCharCode.apply(null, uint8Array));
        //     console.log(base64String);
        //     handleChange('file', base64String);

        // }
        return false;
      },
    };

    const handleChange = (
      key: string,
      value: string | null | ArrayBuffer | undefined,
    ) => {
      const { nodeInternals } = store.getState();
      setNodes(
        Array.from(nodeInternals.values()).map((node) => {
          if (node.id === id) {
            node.data = {
              ...node.data,
              [key]: value,
            };
          }

          return node;
        }),
      );
    };
    const inputNode: INodeProps = {
      id: id,
      label: 'Document',
      icon: <RightCircleOutlined />,
      nodeElements: [
        {
          key: 'input',
          element: (
            <>
              <Dragger {...props}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">Support for a single upload.</p>
              </Dragger>
            </>
          ),
          dragDropHandles: [{ type: 'source', position: Position.Right }],
        },
      ],
    };
    return (
      <>
        <BaseNode node={inputNode} isConnectable={isConnectable} />
      </>
    );
  },
);
