import { BaseNode, INodeProps } from './BaseNode';
import React, { memo, useEffect } from 'react';
import { RightCircleOutlined } from '@ant-design/icons';
import { Position, useReactFlow, useStoreApi } from 'reactflow';
import TextArea from 'antd/es/input/TextArea';
import { Input, InputNumber, Slider } from 'antd';

export default memo(
  ({
    id,
    data,
    isConnectable,
  }: {
    id: string;
    data: any;
    isConnectable: boolean;
  }) => {
    const { setNodes } = useReactFlow();
    const store = useStoreApi();
    useEffect(() => {
      if (!data.chunk_size) {
        handleChange('chunk_size', '1000');
      }
      if (!data.chunk_size) {
        handleChange('chunk_overlap', '100');
      }
      if (!data.chunk_size) {
        handleChange('top_k', '10');
      }
    }, []);
    const handleChange = (key: string, value: string) => {
      const { nodeInternals } = store.getState();
      setNodes(
        Array.from(nodeInternals.values()).map((node) => {
          if (node.id === id) {
            node.data = {
              ...node.data,
              [key]: value,
            };
          }

          return node;
        }),
      );
    };

    const embChromaNode: INodeProps = {
      id: id,
      label: 'Embedding',
      icon: <RightCircleOutlined />,
      headerColor: '#d3adf7',
      tagColor: '#531dab',
      nodeElements: [
        {
          key: 'collection_name',
          label: 'Embedding Collection(Existing)',
          element: (
            <>
              <Input
                className="nodrag nowheel"
                value={data.collection_name}
                onChange={(e) =>
                  handleChange('collection_name', e.target.value)
                }
              />
            </>
          ),
          dragDropHandles: [],
        },
        {
          key: 'chunk_size',
          label: 'Chunk size (characters)',
          element: (
            <>
              <div style={{ display: 'flex' }} className="nodrag">
                <Slider
                  style={{ flex: 1 }}
                  min={1}
                  max={5000}
                  value={data?.chunk_size}
                  step={10}
                  onChange={(v) =>
                    handleChange('chunk_size', v ? v.toString() : '')
                  }
                />
                <InputNumber
                  min={1}
                  max={5000}
                  style={{ margin: '0 0 0 16px' }}
                  step={10}
                  value={data?.chunk_size}
                  onChange={(v) =>
                    handleChange('chunk_size', v ? v.toString() : '')
                  }
                />
              </div>
            </>
          ),
          dragDropHandles: [],
        },
        {
          key: 'chunk_overlap',
          label: 'Chunk overlap (characters)',
          element: (
            <>
              <div style={{ display: 'flex' }} className="nodrag">
                <Slider
                  style={{ flex: 1 }}
                  min={0}
                  max={350}
                  value={data?.chunk_overlap}
                  step={10}
                  onChange={(v) =>
                    handleChange('chunk_overlap', v ? v.toString() : '')
                  }
                />
                <InputNumber
                  min={0}
                  max={350}
                  style={{ margin: '0 0 0 16px' }}
                  step={10}
                  value={data?.chunk_overlap}
                  onChange={(v) =>
                    handleChange('chunk_overlap', v ? v.toString() : '')
                  }
                />
              </div>
            </>
          ),
          dragDropHandles: [],
        },
        {
          key: 'top_k',
          label: 'Number of chunks to query',
          element: (
            <>
              <div style={{ display: 'flex' }} className="nodrag">
                <Slider
                  style={{ flex: 1 }}
                  min={1}
                  max={50}
                  value={data?.top_k}
                  step={1}
                  onChange={(v) => handleChange('top_k', v ? v.toString() : '')}
                />
                <InputNumber
                  min={1}
                  max={50}
                  style={{ margin: '0 0 0 16px' }}
                  step={1}
                  value={data?.top_k}
                  onChange={(v) => handleChange('top_k', v ? v.toString() : '')}
                />
              </div>
            </>
          ),
          dragDropHandles: [],
        },
      ],
      dragDropHandles: [
        {
          type: 'target',
          position: Position.Left,
          label: 'Document',
          id: 'document',
        },
        { type: 'target', position: Position.Top, label: 'Query', id: 'query' },
        { type: 'source', position: Position.Right, label: 'Result' },
      ],
    };
    return (
      <>
        <BaseNode node={embChromaNode} isConnectable={isConnectable} />
      </>
    );
  },
);
